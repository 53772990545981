import fetch from "cross-fetch";

import {chartsApiPath} from "../../../../../../api/src/constants/api_path";
import {IMonthCityStats} from "../../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../../api/src/db_queries/month_city_type_stats_query";
import {substractOneMonth} from "../../../../../../utils/dates";
import {chartsApiUrl, isServer} from "../../../../common/app/read_charts_web_environment_variables";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {mapPathnameToOfferType, OfferType} from "../../../../common/app/routing/offer_type";
import {setFooter} from "../../redux/footer_slice";
import {setHomepageData} from "../../redux/homepage_slice";
import {IActionContext} from "../create_path_to_action";
import {IHomepageFooterState} from "../state/footer/IFooterState";
import {fetchLastAvailableEntry} from "./fetch_last_available_date";
import {setAuthAndUser} from "./set_auth_and_user";
import {setLatestEntryAction} from "./set_latest_entry";

export const homepageAction = async (ctx: IActionContext<Omit<ICityParams, "city">>) => {
    await setAuthAndUser(ctx.store);

    // fetch current date and calculate previous month for data fetching purposes
    const {currentDate} = await fetchLastAvailableEntry();
    const before2Months = substractOneMonth(currentDate);
    const lastMonth = currentDate;
    // prepare fetch required headers with session ID
    const fetchApiHeaders = isServer
        ? {headers: {Cookie: `bd_sessionid=${ctx.req?.cookies["bd_sessionid"] ?? ""}`}}
        : undefined;
    // define api url - empty string for local development, env variable for live environments
    const apiUrl = chartsApiUrl ?? "";
    // fetch data required to generate homepage content - analyst comments and footers
    const dataUrlParams = new URLSearchParams({
        slug_city: "main", // main = magic keyword for fetching 6 main cities
        date_start: before2Months,
        date_end: lastMonth,
        scenario: "home"
    }).toString();
    const cityDataUrl = `${apiUrl}${chartsApiPath.biggestRegionsStats}?${dataUrlParams}`;
    const cityDataRequest = await fetch(cityDataUrl, fetchApiHeaders);
    const cityData = await cityDataRequest.json();
    if (!cityData || cityData.length === 0) {
        throw new Error(`homepageAction: cannot fetch data from URL: ${cityDataUrl}`);
    }
    const cityTypeDataUrl = `${apiUrl}${chartsApiPath.biggestRegionsTypeStats}?${dataUrlParams}`;
    const cityTypeDataRequest = await fetch(cityTypeDataUrl, fetchApiHeaders);
    const cityTypeData = await cityTypeDataRequest.json();
    if (!cityTypeData || cityTypeData.length === 0) {
        throw new Error(`homepageAction: cannot fetch data from URL: ${cityTypeDataUrl}`);
    }

    const offerType = mapPathnameToOfferType(ctx.route.pathname);
    const month = currentDate ?? "";
    // TODO: Enum probably should be of string type, not integer
    const cityTypeDataByOfferType = cityTypeData.filter(
        (row: IMonthCityTypeStats) =>
            offerType === OfferType.PROPERTY ||
            (offerType === OfferType.FLAT && row.offer_type === "Mieszkania") ||
            (offerType === OfferType.HOUSE && row.offer_type === "Domy")
    );
    const cityTypeDataCurrentMonth = cityTypeDataByOfferType.filter((row: IMonthCityTypeStats) => row.date === month);
    const cityTypeDataPreviousMonth = cityTypeDataByOfferType.filter(
        (row: IMonthCityTypeStats) => row.date === before2Months
    );
    const cityDataCurrentMonth = cityData.filter((row: IMonthCityStats) => row.date === month);

    let mostExpensiveCity = cityTypeDataCurrentMonth[0].slug_city;
    let mostExpensiveCityPrice = cityTypeDataCurrentMonth[0].avg_price_m2;
    let cheapestCity = cityTypeDataCurrentMonth[0].slug_city;
    let cheapestCityPrice = cityTypeDataCurrentMonth[0].avg_price_m2;
    for (const row of cityTypeDataCurrentMonth) {
        if (row.avg_price_m2 > mostExpensiveCityPrice) {
            mostExpensiveCityPrice = row.avg_price_m2;
            mostExpensiveCity = row.slug_city;
        } else if (row.avg_price_m2 < cheapestCityPrice) {
            cheapestCityPrice = row.avg_price_m2;
            cheapestCity = row.slug_city;
        }
    }

    const previousMonthAvgPricePerM2 = cityTypeDataPreviousMonth
        .filter((item: IMonthCityStats) => item.avg_price_m2 != null)
        .reduce(
            (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                total + Number(curr.avg_price_m2) / arr.length,
            0
        );
    const currentMonthAvgPricePerM2 = cityTypeDataCurrentMonth
        .filter((item: IMonthCityStats) => item.avg_price_m2 != null)
        .reduce(
            (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                total + Number(curr.avg_price_m2) / arr.length,
            0
        );

    const avgPricePerM2GrowthRate =
        currentMonthAvgPricePerM2 > 0 ? -1 + currentMonthAvgPricePerM2 / previousMonthAvgPricePerM2 : 0;

    const homepageFooterData: IHomepageFooterState = {
        available_offers: cityTypeDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats | IMonthCityStats) => total + Number(curr.available_offers || 0),
            0
        ),
        avg_price_m2: currentMonthAvgPricePerM2,
        avg_price_m2_growth_rate: avgPricePerM2GrowthRate,
        avg_price_m2_studio: cityTypeDataCurrentMonth
            .filter((item: IMonthCityStats) => item.avg_price_m2_studio != null)
            .reduce(
                (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                    total + Number(curr.avg_price_m2_studio) / arr.length,
                0
            ),
        avg_price_m2_2_rooms: cityTypeDataCurrentMonth
            .filter((item: IMonthCityStats) => item.avg_price_m2_2_rooms != null)
            .reduce(
                (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                    total + Number(curr.avg_price_m2_2_rooms) / arr.length,
                0
            ),
        avg_price_m2_3_rooms: cityTypeDataCurrentMonth
            .filter((item: IMonthCityStats) => item.avg_price_m2_3_rooms != null)
            .reduce(
                (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                    total + Number(curr.avg_price_m2_3_rooms) / arr.length,
                0
            ),
        avg_price_m2_4_plus_rooms: cityTypeDataCurrentMonth
            .filter((item: IMonthCityStats) => item.avg_price_m2_4_plus_rooms != null)
            .reduce(
                (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                    total + Number(curr.avg_price_m2_4_plus_rooms) / arr.length,
                0
            ),
        // TODO: there is no such key in https://bigdata.rynekpierwotny.pl/api-charts/biggest-regions-type-stats/?slug_city=main&date_start=2022-12-01&date_end=2023-01-01&scenario=home
        avg_price_m2_house: cityTypeDataCurrentMonth
            .filter((item: IMonthCityStats) => item.avg_price_m2_house != null)
            .reduce(
                (total: number, curr: IMonthCityTypeStats, index: number, arr: IMonthCityStats[]) =>
                    total + Number(curr.avg_price_m2_house) / arr.length,
                0
            ),
        available_studio: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats) => total + Number(curr.available_studio),
            0
        ),
        available_2_rooms: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats) => total + Number(curr.available_2_rooms),
            0
        ),
        available_3_rooms: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats) => total + Number(curr.available_3_rooms),
            0
        ),
        available_4_plus_rooms: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats) => total + Number(curr.available_4_plus_rooms),
            0
        ),
        available_house: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityTypeStats) => total + Number(curr.available_house),
            0
        ),
        most_expensive_city: mostExpensiveCity,
        most_expensive_city_avg_price_m2: mostExpensiveCityPrice,
        cheapest_city: cheapestCity,
        cheapest_city_avg_price_m2: cheapestCityPrice,
        avg_price_m2_delivery_deadline_ready: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_ready / arr.length,
            0
        ),
        avg_price_m2_delivery_deadline_in_3_months: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_in_3_months / arr.length,
            0
        ),
        avg_price_m2_delivery_deadline_in_6_months: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_in_6_months / arr.length,
            0
        ),
        avg_price_m2_delivery_deadline_in_1_year: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_in_1_year / arr.length,
            0
        ),
        avg_price_m2_delivery_deadline_in_2_year: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_in_2_year / arr.length,
            0
        ),
        avg_price_m2_delivery_deadline_above_2_years: cityDataCurrentMonth.reduce(
            (total: number, curr: IMonthCityStats, index: number, arr: IMonthCityStats[]) =>
                total + curr.avg_price_m2_delivery_deadline_above_2_years / arr.length,
            0
        )
    };

    await setLatestEntryAction(ctx.store);
    ctx.store.dispatch(setFooter(homepageFooterData));
    ctx.store.dispatch(setHomepageData({cityData, cityTypeData}));
};
