import fetch from "cross-fetch";
import {format, subMonths} from "date-fns";

import {chartsApiPath} from "../../../../../../api/src/constants/api_path";
import {IMonthCityStats} from "../../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../../api/src/db_queries/month_city_type_stats_query";
import {IMonthDistrictTypeStats} from "../../../../../../api/src/db_queries/month_district_type_stats_query";
import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {substractOneMonth} from "../../../../../../utils/dates";
import {AccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {UserAccessStatus} from "../../../../../../utils/shared_types/user_model";
import {chartsApiUrl, isServer} from "../../../../common/app/read_charts_web_environment_variables";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {mapPathnameToOfferType, OfferType} from "../../../../common/app/routing/offer_type";
import {setAppStatus} from "../../../app_status/app_status_slice";
import {setFooter} from "../../redux/footer_slice";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {IDashboardFooterState} from "../state/footer/IFooterState";
import {fetchLastAvailableEntry} from "./fetch_last_available_date";
import {setAuthAndUser} from "./set_auth_and_user";
import {setError} from "./set_error";
import {setLatestEntryAction} from "./set_latest_entry";

export const cityAction = async (ctx: IActionContext<ICityParams>) => {
    await setAuthAndUser(ctx.store);
    await setLatestEntryAction(ctx.store);

    // establish current date and calculate previous month previous for data fetching purposes
    const {currentDate} = await fetchLastAvailableEntry();
    const {isLoggedIn} = ctx.store.getState().auth.authStatus;
    const userData = ctx.store.getState().users.userData;
    const accessStatus =
        !isLoggedIn || !userData || userData.access_status === UserAccessStatus.REJECTED
            ? AccessStatus.MINIMAL
            : userData.access_status === UserAccessStatus.PENDING ||
                userData.access_status === UserAccessStatus.INACTIVE
              ? AccessStatus.LIMITED
              : AccessStatus.FULL;
    const lastAvailableDate = new Date(currentDate);
    const lastMonth =
        accessStatus === AccessStatus.FULL
            ? format(lastAvailableDate, "yyyy-MM-dd")
            : format(subMonths(lastAvailableDate, 3), "yyyy-MM-dd");
    const before2Months = substractOneMonth(lastMonth);

    const pathname = ctx.route.pathname;

    const {city} = ctx.match.params;
    // if either city or agglomeration view for the given city doesn't exist, display 404 page
    const citySlug = mapParamSlugToCitySlug(city);
    // if city doesn't exists, show 404 page
    if (!citySlug) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }
    // if provided offer type doesn't exist for the given city, display 404 page
    const routeOfferType = mapPathnameToOfferType(pathname);
    if (!getCityOption(citySlug).availableOfferTypes.includes(routeOfferType)) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    if (getCityOption(citySlug).useAgglomerationApi) {
        await setLatestEntryAction(ctx.store);
        return;
    }

    // prepare fetch required headers with session ID
    const fetchApiHeaders = isServer
        ? {headers: {Cookie: `bd_sessionid=${ctx.req?.cookies["bd_sessionid"] ?? ""}`}}
        : undefined;
    // define api url - empty string for local development, env variable for live environments
    const apiUrl = chartsApiUrl ?? "";
    // fetch data required to generate dashboard footer
    const dataUrlParams = new URLSearchParams({
        slug_city: citySlug,
        date_start: before2Months,
        date_end: lastMonth,
        scenario: "dashboard"
    }).toString();
    const cityDataUrl = `${apiUrl}${chartsApiPath.monthCityStats}?${dataUrlParams}`;
    const cityDataRequest = await fetch(cityDataUrl, fetchApiHeaders);
    const cityData = await cityDataRequest.json();
    if (!cityData || cityData.length === 0) {
        throw new Error(`cityAction: cannot fetch data from URL: ${cityDataUrl}`);
    }
    const cityTypeDataUrl = `${apiUrl}${chartsApiPath.monthCityTypeStats}?${dataUrlParams}`;
    const cityTypeDataRequest = await fetch(cityTypeDataUrl, fetchApiHeaders);
    const cityTypeData = await cityTypeDataRequest.json();
    if (!cityTypeData || cityTypeData.length === 0) {
        throw new Error(`cityAction: cannot fetch data from URL: ${cityTypeDataUrl}`);
    }

    const offerType = mapPathnameToOfferType(pathname);
    const month = lastMonth ?? "";

    const cityTypeDataByOfferType = cityTypeData.filter(
        (row: IMonthCityTypeStats) =>
            offerType === OfferType.PROPERTY ||
            (offerType === OfferType.FLAT && row.offer_type === "Mieszkania") ||
            (offerType === OfferType.HOUSE && row.offer_type === "Domy")
    );
    const cityTypeDataCurrentMonth = cityTypeDataByOfferType.find((row: IMonthCityTypeStats) => row.date === month);
    const cityTypeDataPreviousMonth = cityTypeDataByOfferType.find(
        (row: IMonthCityTypeStats) => row.date === before2Months
    );
    const cityDataCurrentMonth = cityData.find((row: IMonthCityStats) => row.date === month);
    const cityDataPreviousMonth = cityData.find((row: IMonthCityStats) => row.date === before2Months);
    const sourceCurrentMonth = offerType === OfferType.PROPERTY ? cityDataCurrentMonth : cityTypeDataCurrentMonth;
    const sourcePreviousMonth = offerType === OfferType.PROPERTY ? cityDataPreviousMonth : cityTypeDataPreviousMonth;

    if (!sourceCurrentMonth || !sourcePreviousMonth) {
        throw new Error(`cityAction: incomplete data fetched from URL: ${cityTypeDataUrl}`);
    }

    const previousMonthAvgPricePerM2 = sourcePreviousMonth.avg_price_m2 || 0;
    const currentMonthAvgPricePerM2 = sourceCurrentMonth.avg_price_m2 || 0;

    const avgPricePerM2GrowthRate =
        currentMonthAvgPricePerM2 > 0 ? -1 + currentMonthAvgPricePerM2 / previousMonthAvgPricePerM2 : 0;

    const footerData: IDashboardFooterState = {
        available_offers: sourceCurrentMonth.available_offers,
        avg_price_m2: currentMonthAvgPricePerM2,
        avg_price_m2_growth_rate: avgPricePerM2GrowthRate,
        avg_price_m2_studio: sourceCurrentMonth.avg_price_m2_studio,
        avg_price_m2_2_rooms: sourceCurrentMonth.avg_price_m2_2_rooms,
        avg_price_m2_3_rooms: sourceCurrentMonth.avg_price_m2_3_rooms,
        avg_price_m2_4_plus_rooms: sourceCurrentMonth.avg_price_m2_4_plus_rooms,
        avg_price_m2_house: sourceCurrentMonth.avg_price_m2_house,
        available_studio: sourceCurrentMonth.available_studio,
        available_2_rooms: sourceCurrentMonth.available_2_rooms,
        available_3_rooms: sourceCurrentMonth.available_3_rooms,
        available_4_plus_rooms: sourceCurrentMonth.available_4_plus_rooms,
        available_house: sourceCurrentMonth.available_house,
        available_investment_type_house: sourceCurrentMonth.available_investment_type_house || 0,
        available_investment_type_x_large: sourceCurrentMonth.available_investment_type_x_large || 0,
        available_investment_type_large: sourceCurrentMonth.available_investment_type_large || 0,
        available_investment_type_medium: sourceCurrentMonth.available_investment_type_medium || 0,
        available_investment_type_small: sourceCurrentMonth.available_investment_type_small || 0,
        available_investment_type_intimate: sourceCurrentMonth.available_investment_type_intimate || 0,
        available_offers_delivery_deadline_above_2_years:
            sourceCurrentMonth.available_offers_delivery_deadline_above_2_years,
        available_offers_delivery_deadline_in_1_year: sourceCurrentMonth.available_offers_delivery_deadline_in_1_year,
        available_offers_delivery_deadline_in_2_year: sourceCurrentMonth.available_offers_delivery_deadline_in_2_year,
        available_offers_delivery_deadline_in_3_months:
            sourceCurrentMonth.available_offers_delivery_deadline_in_3_months,
        available_offers_delivery_deadline_in_6_months:
            sourceCurrentMonth.available_offers_delivery_deadline_in_6_months,
        available_offers_delivery_deadline_ready: sourceCurrentMonth.available_offers_delivery_deadline_ready,
        available_area_above_250_m2: sourceCurrentMonth.available_area_above_250_m2,
        available_area_between_100_and_150_m2: sourceCurrentMonth.available_area_between_100_and_150_m2,
        available_area_between_150_and_200_m2: sourceCurrentMonth.available_area_between_150_and_200_m2,
        available_area_between_200_250_m2: sourceCurrentMonth.available_area_between_200_250_m2,
        available_area_to_100_m2: sourceCurrentMonth.available_area_to_100_m2,
        avg_price_m2_delivery_deadline_ready: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_ready,
        avg_price_m2_delivery_deadline_in_3_months: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_in_3_months,
        avg_price_m2_delivery_deadline_in_6_months: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_in_6_months,
        avg_price_m2_delivery_deadline_in_1_year: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_in_1_year,
        avg_price_m2_delivery_deadline_in_2_year: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_in_2_year,
        avg_price_m2_delivery_deadline_above_2_years: offerType
            ? null
            : cityDataCurrentMonth.avg_price_m2_delivery_deadline_above_2_years,
        demand: sourceCurrentMonth.demand,
        demand_previous_month: sourcePreviousMonth.demand
    };
    if (sourceCurrentMonth.available_offers !== 0) {
        footerData.added_available_ratio = sourceCurrentMonth.added / sourceCurrentMonth.available_offers;
    }

    if (sourcePreviousMonth.available_offers !== 0) {
        footerData.added_available_ratio_previous_month =
            sourcePreviousMonth.added / sourcePreviousMonth.available_offers;
    }

    // if city doesn't have districts enabled, return just city stats
    if (citySlug && !getCityOption(citySlug).districtsMap) {
        await setLatestEntryAction(ctx.store);
        ctx.store.dispatch(setFooter(footerData));
        return;
    }
    // fetch data required to generate dashboard footer on cities with available districts data
    const districtsDataUrlParams = new URLSearchParams({
        slug_city: citySlug as string,
        date_start: before2Months,
        date_end: lastMonth,
        scenario: "dashboard"
    }).toString();
    const districtsDataUrl = `${apiUrl}${chartsApiPath.monthDistrictTypeStats}?${districtsDataUrlParams}`;
    const districtsRequest = await fetch(districtsDataUrl, fetchApiHeaders);
    const districtsData = await districtsRequest.json();

    if (districtsData.length > 0) {
        const districtsAvgPriceMap = districtsData.reduce(
            (accumulator: {[key: string]: number}, currentValue: IMonthDistrictTypeStats) => {
                const slug = currentValue.slug_district;
                if (currentValue.date !== month) {
                    return accumulator;
                }

                if (offerType === OfferType.PROPERTY) {
                    if (accumulator[slug]) {
                        accumulator[slug] = (accumulator[slug] + currentValue.avg_price_m2) / 2;
                        return accumulator;
                    }

                    accumulator[slug] = currentValue.avg_price_m2 || 0;
                    return accumulator;
                }

                if (
                    (offerType === OfferType.HOUSE && currentValue.offer_type !== "Domy") ||
                    (offerType === OfferType.FLAT && currentValue.offer_type !== "Mieszkania")
                ) {
                    return accumulator;
                }

                if (currentValue.avg_price_m2) {
                    accumulator[slug] = currentValue.avg_price_m2 || 0;
                }

                return accumulator;
            },
            {}
        );
        const districtKeys = Object.keys(districtsAvgPriceMap);
        if (districtKeys.length > 0) {
            let mostExpensiveDistrict = districtKeys[0];
            let mostExpensiveDistrictPrice = districtsAvgPriceMap[districtKeys[0]];
            let cheapestDistrict = districtKeys[0];
            let cheapestDistrictPrice = districtsAvgPriceMap[districtKeys[0]];

            for (const key of districtKeys) {
                const value = districtsAvgPriceMap[key];
                if (value > mostExpensiveDistrictPrice) {
                    mostExpensiveDistrictPrice = value;
                    mostExpensiveDistrict = key;
                } else if (value < cheapestDistrictPrice) {
                    cheapestDistrictPrice = value;
                    cheapestDistrict = key;
                }
            }

            footerData.most_expensive_district = mostExpensiveDistrict;
            footerData.most_expensive_district_avg_price_m2 = mostExpensiveDistrictPrice;
            footerData.cheapest_district = cheapestDistrict;
            footerData.cheapest_district_avg_price_m2 = cheapestDistrictPrice;
        }
    }

    ctx.store.dispatch(setFooter(footerData));
};
